import React from 'react'

function DropDownArrow() {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1.09717L8 7.90292L1 1.09717"
        stroke="#092A33"
        stroke-width="2"
      />
    </svg>
  )
}

export default DropDownArrow
