import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Layout from '../components/Layout'
import Seo from '../components/SEO'
import ArticleCard from '../components/Blog/articleCardGrid'
import FurtherReadings from '../components/Blog/furtherReadings'
import CallToAction from '../components/CallToAction'
import DropDownArrow from '../components/SVG/DropDownArrow'

const BlogIndex = ({ data, location }) => {
  const newsArticles = data?.allPrismicNewsArticle?.edges || []
  const page = data?.prismicMedia?.data
  const blog = data?.prismicBlogIndex?.data

  const [selectedCategory, setSelectedCategory] = useState('All')

  const uniqueCategories = Array.from(
    new Set(
      newsArticles.flatMap(article =>
        article.node.data.categories.map(category => category.category_name)
      )
    )
  )

  return (
    <Layout location={location}>
      <Seo
        title={page.meta_title}
        description={page.meta_description}
        location={location}
        canonicalUrl={page.canonical_url.url}
      />
      <div className="mx-6 lg:mx-10 pt-10 lg:pt-40 lg:px-1/24">
        {blog.title && (
          <div className="lg:w-7/11">
            <h2 className="text-3xl md:text-6xl font-bold leading-snug">
              {blog.title}
            </h2>
          </div>
        )}
        <div className="flex justify-between flex-col lg:flex-row">
          {blog.subtitle && (
            <h3 className="text-lg md:text-2xl mt-12 lg:w-7/11">
              {blog.subtitle}
            </h3>
          )}
          <div className="relative mt-12 lg:mt-0 border-green rounded-full inline-flex items-center  2xl:mr-20 3xl:mr-48">
            <select
              className="text-green border font-medium pl-4 pr-4 lg:pl-6 lg:pr-20 py-2 lg:appearance-none rounded-full"
              onChange={e => setSelectedCategory(e.target.value)}
            >
              <option value="All">Categories</option>
              {uniqueCategories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <div className="hidden pointer-events-none absolute lg:right-0 top-0 bottom-0 lg:flex items-center pr-4">
              <DropDownArrow />
            </div>
          </div>
        </div>

        <ArticleCard
          blog={blog}
          newsArticles={newsArticles}
          selectedCategory={selectedCategory}
        />
      </div>
      <div className="bg-green">
        <FurtherReadings blog={blog} />
      </div>
      <CallToAction />
    </Layout>
  )
}

export const blogIndex = graphql`
  query BlogIndex($uid: String) {
    prismicMedia {
      _previewable
      data {
        heading
        media_heading
        link_heading
        link {
          uid
          link_type
          url
          target
        }
        lead_primary {
          html
        }
        assets {
          link {
            uid
            link_type
            url
            target
          }
          link_text
        }
        heading_primary
        heading_secondary
        body_primary {
          html
        }
        meta_title
        meta_description
        canonical_url {
          url
        }
      }
    }
    allPrismicNewsArticle(
      sort: { fields: data___post_date, order: DESC }
      filter: { uid: { ne: $uid } }
    ) {
      edges {
        node {
          _previewable
          uid
          data {
            category
            read_time
            heading
            post_date
            categories {
              category_name
            }
            summary {
              html
              text
            }
            image {
              alt
              localFile {
                childImageSharp {
                  gatsbyImageData(aspectRatio: 2)
                }
              }
            }
            article_author {
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name
                    author_image {
                      alt
                      localFile {
                        childImageSharp {
                          gatsbyImageData(aspectRatio: 2)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicBlogIndex {
      data {
        article_block_heading
        articles_heading
        subtitle
        title
        article {
          article_link {
            uid
            url
            document {
              ... on PrismicNewsArticle {
                id
                data {
                  summary {
                    text
                  }
                  image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(aspectRatio: 1)
                      }
                    }
                  }
                  heading
                  categories {
                    category_name
                  }
                  article_author {
                    document {
                      ... on PrismicAuthor {
                        id
                        data {
                          name
                          author_image {
                            alt
                            localFile {
                              childImageSharp {
                                gatsbyImageData(aspectRatio: 2)
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(BlogIndex)
