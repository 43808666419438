import React, { useState } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function ArticleCard({ newsArticles, selectedCategory }) {
  const [numArticlesToShow, setNumArticlesToShow] = useState(9)

  const renderCategories = categories => {
    return categories.map(category => (
      <div className="border uppercase text-xs text-pink px-3 py-1 inline-block border-pink rounded-full">
        {category.category_name}
      </div>
    ))
  }

  const loadMoreArticles = () => {
    setNumArticlesToShow(prevNum => prevNum + 9)
  }

  const renderArticle = (article, index) => {
    if (index >= numArticlesToShow) {
      return null
    }

    let item = article?.node?.data
    let uid = article?.node?.uid
    let author = article?.node?.data?.article_author?.document?.data?.name || ''
    let author_image =
      article?.node?.data?.article_author?.document?.data?.author_image || null

    if (
      selectedCategory === 'All' ||
      (item &&
        item.categories.some(
          category => category.category_name === selectedCategory
        ))
    ) {
      return (
        <Fade key={index} ssrReveal delay={250 + 250 * (index + 1)}>
          <div className="flex flex-col">
            {item.image && (
              <Link to={`/blog/${uid}/`}>
                <GatsbyImage
                  image={getImage(item.image.localFile)}
                  alt={item.image.alt}
                />
              </Link>
            )}
            <div className="border border-green border-t-0 flex-grow">
              {item.heading && (
                <Link
                  className="p-6 h-full border-t-0 hover:opacity-100 focus:opacity-100 link-group w-full flex flex-col items-start"
                  to={`/blog/${uid}/`}
                >
                  <div className="flex gap-x-2">
                    {renderCategories(item.categories)}
                  </div>

                  <h5 className="inline-block mt-6 text-lg leading-snug font-bold pb-3 lg:pb-0">
                    {item.heading}
                  </h5>
                  {item.summary.text !== '' && (
                    <div
                      className="rte mb-3 lg:mb-0 lg:mt-5 pb-10"
                      dangerouslySetInnerHTML={{
                        __html: item.summary.html,
                      }}
                    ></div>
                  )}

                  <div className="flex justify-between items-center mt-auto w-full">
                    <div className="flex items-center">
                      {author_image && (
                        <GatsbyImage
                          className="rounded-full w-10 h-10 mr-3"
                          image={getImage(author_image.localFile)}
                          alt={author_image.alt}
                        />
                      )}
                      <p className="font-medium">{author}</p>
                    </div>
                    {item.read_time && <p>{item.read_time} Minutes</p>}
                  </div>
                </Link>
              )}
            </div>
          </div>
        </Fade>
      )
    } else {
      return null
    }
  }

  return (
    <div className="py-12 lg:py-24">
      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-y-12 md:gap-4 lg:gap-8 mt-12 lg:mt-22 max-w-7xl mx-auto">
        {newsArticles && newsArticles.map(renderArticle)}
      </div>
      {newsArticles && numArticlesToShow < newsArticles.length && (
        <div
          onClick={loadMoreArticles}
          className="flex text-pink mt-8 items-center pt-12 cursor-pointer justify-center"
        >
          <div class="mr-4 font-medium leading-tight">Load more</div>
          <svg width="20" height="11" viewBox="0 0 20 11" fill="none">
            <g clip-path="url(#clip0_3_2796)">
              <path
                d="M18 1.64801L10 9.42601L2 1.64801"
                stroke="#FF8188"
                stroke-width="2"
              />
            </g>
            <defs>
              <clipPath id="clip0_3_2796">
                <rect
                  width="11"
                  height="19"
                  fill="white"
                  transform="translate(19.5) rotate(90)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
    </div>
  )
}

export default ArticleCard
